import React from 'react';
import ReactDOM from 'react-dom';

import './assets/styles/style.scss';
import './assets/styles/custom.scss';
import './assets/styles/image-upload.scss';
import App from './App';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

reportWebVitals();
