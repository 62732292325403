import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

// UnAuthenticated
const Login                 = lazy(() => import("./pages/UnAuthenticated/Login"));
const Register              = lazy(() => import("./pages/UnAuthenticated/Register"));
const ForgotPassword        = lazy(() => import("./pages/UnAuthenticated/ForgotPassword"));
const ActivateAccount       = lazy(() => import("./pages/UnAuthenticated/ActivateAccount"));
const ActivateUserAccount   = lazy(() => import("./pages/UnAuthenticated/ActivateUserAccount"));
const UpdatePassword        = lazy(() => import("./pages/UnAuthenticated/UpdatePassword"));

// Authenticated
//const PortalHome            = lazy(() => import("./pages/Authenticated/PortalHome"));
const Appointments          = lazy(() => import("./pages/Authenticated/Appointments/Appointments"));
const AppointmentDetails    = lazy(() => import("./pages/Authenticated/Appointments/Components/AppointmentDetails"));
const Clients               = lazy(() => import("./pages/Authenticated/Clients/Clients"));
const ClientDetails         = lazy(() => import("./pages/Authenticated/Clients/Components/ClientDetails"));
const Patients              = lazy(() => import("./pages/Authenticated/Patients/Patients"));
const PatientDetails        = lazy(() => import("./pages/Authenticated/Patients/Components/PatientDetail"));
const Profile               = lazy(() => import("./pages/Authenticated/Profile/Profile"));
const Settings              = lazy(() => import("./pages/Authenticated/Settings/Settings"));
const StaffMembers          = lazy(() => import("./pages/Authenticated/Staff/StaffMembers"));
const StaffMemberDetail     = lazy(() => import("./pages/Authenticated/Staff/StaffMemberDetail"));
const Services              = lazy(() => import("./pages/Authenticated/Services/Services"));
const Sales                 = lazy(() => import("./pages/Authenticated/Sales/Sales"));

function App() {
    return (
        <ToastProvider placement="bottom-left">
            <Router>
                <Suspense fallback={
                    <div className="page-transition animated fadeIn">
                        <div className="loading">
                            <span/><span/><span/><span/>
                        </div>
                    </div>
                }>
                    <Switch>
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/"}
                            component={Login}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/login"}
                            component={Login}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/signup"}
                            component={Register}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/forgot-password"}
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/update-password"}
                            component={UpdatePassword}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/activate-account"}
                            component={ActivateAccount}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/activate-user-account"}
                            component={ActivateUserAccount}
                        />

                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/home"}
                            component={Appointments}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/appointment-detail"}
                            component={AppointmentDetails}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/clients"}
                            component={Clients}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/client-detail"}
                            component={ClientDetails}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/patients"}
                            component={Patients}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/patient-detail"}
                            component={PatientDetails}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/profile"}
                            component={Profile}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/settings"}
                            component={Settings}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/staff"}
                            component={StaffMembers}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/staff-member"}
                            component={StaffMemberDetail}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/services"}
                            component={Services}
                        />
                        <Route
                            exact
                            path={process.env.PUBLIC_URL + "/sales"}
                            component={Sales}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </ToastProvider>
    );
}

export default App;
